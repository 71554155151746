@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

// Override default variables before the import
$body-bg: #fff;
$primary: #f93549;
$light: #979ca1;
$secondary: #c0cc5c;
$info: #78c4d4;
$dark: #636465;
$font-family-sans-serif: 'Trade Gothic', sans-serif !default;

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./utils/fonts/brandon-grotesque-thin-italic-58a8a3a8861fe.otf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./utils/fonts/brandon-grotesque-light-58a8a4b38001d.otf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./utils/fonts/brandon-grotesque-light-italic-58a8a4ccb88be.otf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./utils/fonts/BrandonGrotesque-Regular.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./utils/fonts/brandon-grotesque-regular-italic-58a8a456c4724.otf');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./utils/fonts/brandon-grotesque-medium-italic-58a8a3c40866a.otf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./utils/fonts/BrandonGrotesque-Medium.otf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./utils/fonts/brandon-grotesque-bold-italic-58a8a48221563.otf');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./utils/fonts/brandon-grotesque-black-58a8a3e824392.otf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Trade Gothic';
  src: url('./utils//fonts/Trade-Gothic-LT-Light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Trade Gothic';
  src: url('./utils//fonts/Trade-Gothic-LT.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Trade Gothic';
  src: url('./utils//fonts/Trade-Gothic-LT-Bold.ttf');
  font-weight: 800;
  font-style: normal;
}

$enable-rounded: false;
$enable-negative-margins: true;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 3.5,
  7: $spacer * 4,
  8: $spacer * 4.5,
  9: $spacer * 5,
  10: $spacer * 5.5,
);

$font-weight-lighter: 200;
$font-weight-light: 300;
$font-weight-normal: 300;
$font-weight-bold: 400;
$font-weight-bolder: 800;

$font-weight-base: $font-weight-normal;

.nav-item:hover {
  border-bottom: #f93549 solid 3px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

#subscribeblock::placeholder {
  font-weight: bold;
  color: $primary;
}

.dropdown-menu-dark {
  background-color: $primary !important;
}

.dropdown-item:active,
.dropdown-item:hover {
  background-color: $secondary !important;
}

.carousel .carousel-item {
  height: 50vh;
}

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base;
$h7-font-size: $font-size-base;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
);

.bg-grad {
  background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(120, 196, 212, 1) 100%
    ),
    linear-gradient(white, white),
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(192, 204, 92, 0.8) 100%
    );
  background-size: 25% 100%;
  background-position: 0 0, 25vw 0, 75vw 0;
  background-repeat: no-repeat no-repeat;
  background-attachment: fixed;
  height: auto;
  width: 100%;
}

.accordion-header,
button,
.btn,
.card {
  transition: all ease 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 8px 24px 4px rgba(0, 0, 0, 0.3) !important;
  }
}

@media (max-width: 1200px) {
  .hero-banner {
    margin-left: 0 !important;
  }
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
