.navbar .nav-item .dropdown-menu {
  display: none;
}

.navbar .nav-item:hover .dropdown-menu {
  display: block;
}
.navbar .nav-item .dropdown-menu {
  margin-top: 0;
}

.nav-item {
  cursor: pointer;
}

.searchInput {
  &:focus {
    box-shadow: none !important;
    border-color: #ced4da !important;
  }
}

button.btn {
  transition: all 0.5s;
  span {
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  span::after {
    content: '\0020D7';
    position: absolute;
    opacity: 0;
    font-size: 2em;
    top: 0;
    right: -0.4em;
    transition: 0.5s;
  }
  &:focus {
    box-shadow: none;
  }
  &:hover {
    box-shadow: none !important;
    span {
      transform: translate3d(-0.5em, 0, 0);
      &::after {
        opacity: 1;
        right: -0.8em;
      }
    }
  }
}
