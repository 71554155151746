.form-input {
    border: 1px solid #eaeaea;
  
    &:focus {
      box-shadow: none !important;
    }
    &::placeholder {
      font-size: 0.8em;
      color: #6B7280 !important;
    }
  }

  .signin-left-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .formButton {
    &:disabled {
      background-color: #d9d9d9 !important;
      color: white !important;
    }
  }
  
  label {
    font-family: "Brandon Grotesque";
    font-weight: 400;
  }
  
  .divider {
    height: 1rem;
    width: 100%;
    background-color: #f1f1f1;
  }
  
  .submit-btn {
    &:hover {
      background-color: #c0cc5c !important;
    }
  }