.card .active {
  visibility: hidden;
  height: 0;
  opacity: 0;
  overflow: scroll;
}

.card:hover .active {
  visibility: visible;
  opacity: 1;
  overflow: auto;
  height: 35vh;
  transition: all 0.4s ease;
}
